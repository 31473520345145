import { ContentData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../../components/View';
import Form from '../../../../../../../../containers/Espace/Form';
import params from '../../../../../../../../params/produit/content.json';
import TemplateEspace from '../../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../../utils/requireUser';

const PageEspaceCanalBannerContent: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { bannerId, channelId, contentId, espaceId },
}) => {
  const contentData = new ContentData({
    espaceId,
    params,
    parentCollectionName: `banners`,
    parentId: bannerId,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          docId={contentId}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/banners/${bannerId}/content/`}
          itemPathnameSuffix=""
          model={contentData}
          name="content"
          params={{ bannerId, channelId }}
          type="update"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceCanalBannerContent);
